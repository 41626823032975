import { mutate } from "swr";
import Felt from "../lib/Felt";
import syncExt from "./syncExt";
import autoMutate from "./autoMutate";

const endpoint = {
  development: 'http://127.0.0.1:3721',
  // development: 'https://api.holli.cc',
  production: 'https://api.holli.cc',
}[process.env.NODE_ENV]

const jwtToken = {
  __last : localStorage.getItem( 'api_token' ) ,
  get(){ return this.__last },
  set(v){ localStorage.setItem( 'api_token', v ); this.__last = v }
}

const $api = new Felt( endpoint, jwtToken,
  ( resData, req )=>{
    if( ['GET::/auth/me','POST::/user'].indexOf(`${req.method}::${req.path}`) > -1 ){
      syncExt({ token: $api.jwtToken.get() })
    }
    req.method === 'POST' && autoMutate( req.path, resData )
    return resData
  },
  (e,req)=>{
    if( e.response && e.response.status === 401 ) syncExt({ token:'invalid' })
    throw e
  },
)

/**
* Get Readable Stream
*/
const getStream = async( path, handle )=>{
 const res = await fetch( endpoint+path, {
    headers: { Authorization : $api.jwtToken.get() ? `Bearer ${ $api.jwtToken.get() }` : null }
  })
 if (!res.ok) { throw Object.assign({ code: res.status }, await res.json()) }
 const decoder = new TextDecoder("utf-8")
 const reader = res.body.getReader();
 var content = ''
 while(true){
   const { done, value } = await reader.read();
   const _t = decoder.decode( value ); content += _t
   handle({ done, value : done ? content : _t })
   if( done ) break
 }
}

const logout = async()=>{
  return $api.get('/auth/logout').catch(e=>e).then( ()=> {
    $api.jwtToken.set('')
    mutate('/auth/me',null)
  })
}

export default $api
export { getStream, logout, endpoint }