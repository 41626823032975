import React from 'react'

import { AppFrame } from '../App'
import Ani from '../../components/ani';
import CardVideos from './components/card-videos';
import PageFooter from '../../components/page-footer';
import ListWords from '../../components/list-words';
import useSWRImmutable from 'swr/immutable';

/**
 * @next get chat bots from remote
 */
function Home(){

  const { data : countWord = 0 } = useSWRImmutable('/word/count')

  return (
    <AppFrame>
      <Ani className='container pb-100'>

        <header className='center my-80'>
          <h1 className='bold' style={{ fontSize:'2.1em', letterSpacing:'-0.02em' }}>
            Crossfit Gym for <span className='border-primary border-b' style={{borderBottomWidth:'4px' }}>Language</span> Learning
          </h1>
        </header>

        {/* <CardChat className='pt-30' /> */}

        {/* Videos */}
        <CardVideos className='mt-30 left bg-ink border-lighten p-20 round'/>

        {/* <section className="left mt-30 pl-2">
          <p className="mb-10 th-36 t-5 bold">Games</p>
          <div className="inline h-54 border-lighten round tapable bg-ink pl-10 pr-5" onClick={ navigate.bind(null,`/chat/create/Wordle`) } >
            <span className="icon icon-game th-54 silver" />
            <h3 className="ml-5 inline t-6 bold silver th-54">Wordle</h3>
            <span className="ml-2 icon icon-right t-6 mr-5 th-54 silver" />
          </div>
          <div className="inline h-54 border-lighten round tapable bg-ink pl-10 pr-5 ml-5" onClick={ navigate.bind(null,`/chat/create/Scrambles`) } >
            <span className="icon icon-game th-54 silver" />
            <h3 className="ml-5 inline t-6 bold silver th-54">Scrambles</h3>
            <span className="ml-2 icon icon-right t-6 mr-5 th-54 silver" />
          </div>
        </section> */}

        {/* MyWords */}
        {/* <CardMyWords className='mt-30 left bg-ink border-lighten p-20 round' /> */}
        
        {/* Speaking */}
        {/* <div className='mt-30 left bg-ink p-20 round'>
          <h2 className='bold'>Tracks</h2>
          <p className='t-6 silver'>Repeat sentences, not just word</p>
          <TodayTracks className='mt-20' />
        </div> */}

        {/* Listening: Tracks */}
        {/* <div className='mt-50 left'>
          <TodayTracks />
        </div> */}

        {/* <div className='mt-20 left'>
          <h2 className='t-5 silver mb-10'>Logs</h2>
          <p className='t-6 silver mb-20'>You recently played.</p>
          <ListChats maxSize={5} psize={5} />
        </div> */}

        <div className='mt-50 left'>
          <h2>
            <span className='t-3 bold'>Collected Words</span>
            <span className='th-3 h-44 px-5 t-5 bg-primary round black bold ml-10'>{ countWord }</span>
          </h2>
          <p className='mb-20 t-6 silver'>Word will be auto-collected when clicked to translate(like click the caption).</p>
          <ListWords />
        </div>
        
        <div className='mt-100'><PageFooter /></div>
      </Ani>
    </AppFrame>
  )

}

export default Home