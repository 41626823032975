import Markdown from './markdown';
import React, { useEffect, useState } from 'react';
import $api, { getStream } from '../core/api';
import { openWordBox } from './box-word';
import { mutate } from 'swr';
import util from '../core/util';
import toast from '../core/toast';

export const MsgBotNormal = ({ data }) => {
  return <div className={`msg msg-bot border-lighten bg-ink round p-20`}>
    <div className='inline w-full'>
      <div className='left'>
        <div className='markdown' style={{ minHeight:'41px' }}>
          <Markdown content={ (data.content || '') + ( data.pending ? '[caret](caret)' : '' ) }
            clickWord={ ( word, sentence, e )=> openWordBox( word, e.target, sentence ) }/> 
        </div>
      </div>
    </div>
  </div>
}

export const MstBotCompletionStream = ({ data })=>{
  const [content, setContent] = useState('')
  // Auto fetch streamContent
  useEffect( ()=>{
    if( !data.pending ) return
    var content = data.content || ''
    const hanlder = ({ done, value }) => {
      if( !done ){
        if( typeof value === 'string' ){ content += value; setContent( content ) }
      }
      else{
        mutate(
          `/chat/${ data.cid }/messages`,
          { id: data.id, content:value, pending: null },
          { populateCache: ( _msg, _raw ) => util.arrObj.update( _raw, _msg ), revalidate: false, optimisticData: true }
        )
        $api.post(`/chat/${data.cid}/next`)
      }
    }
    getStream( `/message/${ data.id }/resolve/completion_stream`, hanlder ).catch( e => {
      const _info = e?.message || 'Something went wrong!'
      toast( `${_info} (${e?.code})`,'error' ); hanlder({ done: true, value: _info })
    })
  },[ data ] )
  return <MsgBotNormal data={ { ...data, content } } />
}