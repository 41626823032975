import React from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';

import Gate from '../pages/Gate';
import Chat from '../pages/Chat';

import Vocab from '../pages/Vocab';
import Profile from '../pages/Profile';
import Track from '../pages/Track/Track';
import Home from '../pages/Home/Home';
import App from '../pages/App';
import $api, { logout } from './api';
import Transcript from '../pages/Transcript';
import TranscriptDetail from '../pages/TranscriptDetail';
import Onboard from '../pages/Onboard';
import { mutate } from 'swr';
import util from './util';
import { fireEvent } from '../hooks/useEvent';

const PUBLIC_PATHS = [ '/gate' ]

const $router = createBrowserRouter([
  //App
  {
    element: <App publicPaths={ PUBLIC_PATHS } />,
    children : [
      { path: "/", loader: ()=>{ throw redirect('/home') } },
      { path: "/onboard", element: <Onboard /> },
      { path: "/gate/logout", loader: async()=> logout().finally( () =>{ throw redirect('/gate') }) },

      { path: "/gate", element: <Gate />, loader: async ({ request })=>{
        const { handle, otp, token, forward, via } = Object.fromEntries( new URL(request.url).searchParams )
        const to = forward || util.local.get( 'gate_forward' ) || '/'
        util.local.save('gate_forward',null)
        try{  // Check Authed
          if(handle && otp) await $api.post('/auth/login/hfotp', { username: handle, password: otp }, true )
          else{
            token && $api.jwtToken.set( token )
            await $api.get('/auth/me',true).then( _me => mutate('/auth/me',_me) )
          }
          throw redirect( to )
        }catch(e){ // Not Authed
          return { forward: to, via }
        }
      }},

      { path: "/home", element: <Home /> },
      { path: "/vocab", element: <Vocab /> },
      { path: "/profile", element: <Profile /> },
      
      { path: "/transcript", element: <Transcript /> },
      { path: '/transcript/:tsid', element: <TranscriptDetail /> },
      
      { path: '/track/:tid', element: <Track /> },

      { path: '/chat/create/:bot',
        loader: async ({ params })=>{
          const { bot } = params
          await $api.post('/chat',{ bot } ).then( _c => {
            _c && fireEvent('/chat/list/', _raw => util.arrObj.presert( _raw, _c) )
            throw redirect( _c ? `/chat/${_c.id}` : '/home')
          })
        }
      },
      { path: '/chat/:cid', element: <Chat /> },
      
    ]
  },
]);

export default $router