import React from 'react'

import PageFooter from '../components/page-footer'
import Ani from '../components/ani'
import useSWRImmutable from 'swr/immutable'
import { Navigate, useLoaderData } from 'react-router-dom'
import toast from '../core/toast'
import FormEmail from '../components/form-email'
import util from '../core/util'
import BoxLoginWechat from '../components/box-login-wechat'
import { closeLayer, openLayer } from '../components/layer'
import $api, { endpoint } from '../core/api'
import $v from '../core/values'
import gSvg from '../assets/images/google.svg'
import wxSvg from '../assets/images/wechat.svg'
import Loading from '../components/loading'

function Gate(){

  // @ts-ignore
  const { forward } = useLoaderData()
  const { data:me } = useSWRImmutable('/auth/me')

  if(me?.id){
    if( forward.indexOf('http')> -1 ){
      window.location.replace( forward )
      return <Loading />
    }
    else{
      toast('Greetings, welcome Back!') 
      return <Navigate to={ forward } replace={true} />
    }
  }

  else return (
    <div className='page gate center py-100'>
      <Ani className='ab-tl' style={{ left:'2vw', top:'2vw' }} type='fadein'>
        <div className='inline wh-48 center bg-primary' style={{ borderRadius:'34%' }}>
          <span className='icon icon-ho black th-48' style={{ fontSize:'40px'}} />
        </div>
      </Ani>

      <div className='container center'>
        <Ani className='center mt-30' type='fadeup' delay={200}>
          <h2 className='bold' style={{ fontSize:'2.1em', letterSpacing:'-0.02em' }}>
            Crossfit Gym for <span className='border-primary border-b' style={{borderBottomWidth:'4px' }}>Language</span> Learning
          </h2>
        </Ani>

        {/* Sign in */}
        <Ani className='inline mt-80' style={{ maxWidth:'320px' }} type='fadeup' delay={400}>
          <div>
            <FormEmail onSubmit={ signInEmail } />
          </div>
          <div className='mt-30' style={{ borderTop:'1px solid #fff3' }}>
            <p className='t-6 silver bold my-10'>Or login with</p>
            <div className='h-48 round-full border-gray white tapable center' onClick={ ()=>toGoogleAuth(forward) }>
              <img alt='Google' src={ gSvg } className='wh-24 mr-10' style={{ marginTop:'12px' }} />
              <span className='th-48 bold t-5' >Continue with Google</span>
            </div>
            <div className='h-48 round-full border-gray white tapable center mt-10' onClick={ signInWechat }>
              <img alt='Wechat' src={ wxSvg } className='wh-24 mr-10' style={{ marginTop:'12px' }} />
              <span className='th-48 bold t-5 mt' >Continue with Wechat</span>
            </div>
          </div>
        </Ani>
      </div>
      <div className='fix-b w-full center'><PageFooter /></div>

    </div>
  )
}

const toGoogleAuth = forward =>{
  util.local.save('gate_forward', forward )
  window.location.href = endpoint + '/auth/login/google' // => google =back=> gate?token
}

const signInWechat = () => {
  if( $v.iswx ) window.location.href = `https://bjlx.top/half-api/otp?to=${window.location.origin + window.location.pathname}`
  else{
    openLayer( ({ visible }) =>
      <BoxLoginWechat visible={ visible } onSubmit={
        ({handle, otp})=> $api.post('/auth/login/hfotp',{ username: handle, password: otp } ).then(
          _me => { closeLayer(); _me && toast('Log in successful!(OTP)') }
        )
      }/>
    )
  }
}

const signInEmail = (data, existed) => {
  $api.post( existed ? '/auth/login/email' : '/auth/register/email', data ).then( _me => _me && toast('Log in successful!(Email)') )
}

export default Gate