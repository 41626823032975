import axios from "axios"

/**
 * Request
 * - handle `jwt token` in header
 * @return {Promise<*>} data
 */
export default class Felt {

  endpoint
  jwtToken
  onData
  onError

  constructor(
    endpoint,
    jwtToken,
    onData = null,
    onError = null,
  ){
    this.endpoint = endpoint
    this.jwtToken = jwtToken
    this.onData = onData
    this.onError = onError
  }

  async request( path, data = {}, options ){
    // console.log('[call api]', `${options.method}::${path}`)
    const req = Object.assign({
      url: this.endpoint+path, data,
      headers: { Accept : 'application/json', Authorization : this.jwtToken.get() ? `Bearer ${ this.jwtToken.get() }` : null }
    }, options )
    return await axios( req ).then(res => {
      res.headers.token && this.jwtToken.set(res.headers.token)
      return this.onData ? this.onData( res.data, Object.assign( { path, data }, options ) ) : res.data
    }).catch( e =>{
      if(this.onError) this.onError( e, Object.assign( { path, data }, options ) )
      else throw e
    })
  }
  
  async get( path, options= {} ){ return this.request( path, null, { ...options, method: 'GET' } ) }
  
  async post( path, data={ }, options = {} ){ return this.request( path, null, { ...options, method: 'POST', data }) }


}