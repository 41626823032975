import React, { useState } from "react"
import FoRadio from "../assets/FoForm/FoRadio"
import { lang_target, lang_native } from '../assets/langs'
import Button from '../components/button'
import util from "../core/util";
import toast from "../core/toast";
import $api from "../core/api";
import Input from "./input";

export default function BoxSettings({ onSaved = null, defaultProfile = { nickname:'', settings:{} } }){

  const [form,setForm] = useState(defaultProfile)
  const dirty = !util.obj.deepEqual( defaultProfile, form )

  const toSave = () => {
    $api.post('/user', {
      settings: form.settings,
      nickname: form.nickname || 'Polyglot'
    }).then( ()=>{ toast('Profile updated!'); onSaved && onSaved() })
  }

  return (
    <div className='mt-30 p-20 border-lighten bg-ink round'>
      <h2 className='bold t-5 silver'>Settings</h2>

      <div className="left">
        <p className="bold th-54">nickname</p>
        <Input className="border-lighten" placeholder={ defaultProfile.nickname || 'Noname' } value={ form.nickname } onChange={ v => setForm({ ...form, nickname: v }) } />
      </div>

      <div className='left mt-20'>
        <p className='mb-10'>
          <span className="bold">I'm learning</span>
          {/* <span className="t-6 th-2 silver ml-5">| Target Language</span> */}
          {/* <span className="t-1 mr-10 fright"> 🎯</span> */}
        </p>
        <FoRadio value={[ form.settings?.language_target ]}
          options={ lang_target } valueKey='code' labelKey='nativeName'
          onChange={ v => setForm({ ...form, settings: { ...form.settings, language_target : v[0] } }) } />
      </div>

      <div className='left mt-20'>
        <p className='mb-10'>
          <span className="bold">Translate to</span>
          {/* <span className="t-6 th-2 silver ml-5">| Native Language</span> */}
          {/* <span className="t-1 mr-10"> 🌱</span> */}
        </p>
        <FoRadio value={[ form.settings?.language_native ]}
          options={ lang_native } valueKey='code' labelKey='nativeName'
          onChange={ v => setForm({ ...form, settings: { ...form.settings, language_native : v[0] } }) } />
      </div>


      { dirty && <div className='mt-20 w-full right'>
        <Button classes='mr-5' ghost text='Reset' color={['silver','white']} style={{ width:'100px' }}
          disabled={ !dirty }
          onClick={ () => setForm( defaultProfile ) } />
        <Button text='Save' style={{ width:'100px' }}
          disabled={ !dirty || !form.settings.language_native || !form.settings.language_target }
          onClick={ toSave } />
      </div> }
    </div>
  )
}